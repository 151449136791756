@import 'media-query';
@import 'variables';

$darkblue: #233b7d;
$lightblue: #41beef;

body {
  font-size: 16px;
}

#createUsersForm {
  .mylabel {
    display: inline-block;
  }
  label, .mylabel {
    margin-top: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  textarea {
    min-height: 100px;
  }
}

div.radio, div.check {
  label {
    margin: 0 !important;
  }
}

.panel {
  margin-top: 30px;
}

.lightblue {
  color: $lightblue;
}

span.vet {
  font-weight: bold;
  color: $darkblue !important;
}

span.webinar {
  font-weight: bold;
  color: $lightblue !important;
}

span.vip {
  font-weight: bold;
  color: goldenrod  !important;
}

#klubtag {
  display: none;
}

.btn-primary {
  background: $darkblue;
  font-weight: bold;
  border: none;
  &:hover {
    background: $lightblue;
  }
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

a {
  color: $lightblue;
}

.info {
  background: #f2f2f2;
  padding: 30px 0 !important;
}

#program {
  td {
    padding: 0;
  }
  span{
    display: block;
    padding-left: 5px;
  }
  .time {
    text-align: center;
    background: $lightblue;
    color: #fff;
  }
  .title {
    display: block;
    font-weight: bold;
    padding: 0 5px 5px 5px;
  }
  .presenter {
    padding: 5px 5px 0 5px;
    font-style: italic;
  }

  .presenter-title {
    font-size: 10pt;
  }
}
.program span{
  padding: 0 !important;
}
footer {
  margin-top: 30px;
  background: $lightblue;
  color: #fff;
  padding: 15px;
}

footer a {
  color: #fff;
}

footer ul {
  list-style-type: none;
}

.dropdown-menu > li > a:hover {
  background: #41beef
}

.navbar a.navbar-brand {
  color: #41beef !important
}

.jumbotron * {
  color: #000 !important;
}

.jumbotron h1 {
  font-size: 24px;
}

.jumbotron h2 {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .jumbotron h1 {
    font-size: 28px;
  }
  .jumbotron h2 {
    font-size: 22px;
  }
}

.navbar {
  margin-bottom: 0;
}

.dropdown-menu {
  padding: 0;
}

.dropdown-menu > li > a {
  padding: 5px 10px;
}

.jumbotron.bg-ful {
  background: linear-gradient(to left, #FFD44F, #FFEEC6);
}

.jumbotron.bg-uh {
  background: linear-gradient(to left, #481919, #866666);
}

.jumbotron.bg-szem {
  background: linear-gradient(to left, #69C293, #C3E4CF);
}

.jumbotron.bg-orto {
  background: linear-gradient(to left, #0065AB, #D0EBFE);
}

.jumbotron.bg-bor {
  background: linear-gradient(to left, #F16107, #F5BB6F);
}

.jumbotron.bg-fog {
  background: linear-gradient(to left, #FF66FF, #F6ADCD);
}


table.reszvetel tr td{
  border: none;
}

ul.megjegyzes {
  padding : 0;
  list-style-type: none;
  font-size: 12px;
}